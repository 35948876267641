import React from 'react'
import Page from '../components/Homemade/ProductPage/ProductPage'
import generatePagePropsOnBuildTime from '../helpers/generatePagePropsOnBuildTime'

const Product = (props) => {
    return <Page {...generatePagePropsOnBuildTime(props, {
        product: {
            images: [],
            sizes: []
        }
    })} />
}

export default Product